

































import Vue from "vue"
import Component from "vue-class-component"
import {State} from "vuex-class"

import ActionButton from "components/button/ActionButton.vue"
import Badge from "components/supplemental/Badge.vue"
import CardSection from "components/supplemental/CardSection.vue"
import Notice from "components/supplemental/Notice.vue"
import Card from "components/panel/Card.vue"
import Jumbotron from "components/panel/Jumbotron.vue"
import Checkbox from "components/form/Checkbox.vue"
import Message from "components/widget/Message.vue"

import ImageCard from "custom/ImageCard.vue"
import MercerMenu from "custom/MercerMenu.vue"
import Page from "custom/Page.vue"
import Salutation from "custom/Salutation.vue"
import Welcome from "custom/Welcome.vue"

import {User} from "store/user/types"

import Secured from "lib/security/Secured"
import {session} from "src/mercerAPI/request"

@Secured(session)
@Component({
	components: {
		ActionButton,
		Badge,
		Card,
		CardSection,
		Checkbox,
		ImageCard,
		Jumbotron,
		MercerMenu,
		Message,
		Notice,
		Page,
		Salutation,
		Welcome
	}
})
export default class Index extends Vue {
	@State lastLogin!: Date | null
	@State user!: User
}
